import cn from 'classnames'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import Section from '../Section'
import Button from '../Button'
import { createUseStyles } from '../../helpers/createStyles'

import useScrollTrigger from '../../hooks/useScrollTrigger'

const TextSlice = ({ data, first, page }) => {
  const styles = useStyles()
  const {
    content,
    include_button: hasButton,
    button
  } = data

  const isJournal = page?.parent?.slug === 'journal'

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`.${styles.container}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
      tl.from(ref.current.querySelectorAll(`.${styles.buttonContainer}`), { y: 40, opacity: 0, ...defaults }, '-=0.5')
    }
  )

  return (
    <Section grid className={cn(styles.section, { first })} ref={sectionRef} noBottomMargin={isJournal}>
      <RichContent content={content} className={cn(styles.container, isJournal && styles.journalContainer)} />
      {hasButton && (
        <div className={styles.buttonContainer}>
          <Button size='lg' className={styles.button} link={button} />
        </div>
      )}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    '&.first': {
      paddingTop: theme.spacingPx(18)
    }
  },
  container: {
    gridColumn: '1 / span 8',
    [theme.breakpoints.up('md')]: {
      gridColumn: '2 / span 9'
    },
    '& h1': {
      marginBottom: theme.spacingPx(6)
    },
    '& ul': {
      padding: '0 0 0 18px',
      margin: '0 0 1.5em'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      marginTop: theme.spacingPx(6),
      marginBottom: '1.5em'
    }
  },
  journalContainer: {
    marginBottom: theme.spacingPx(5),
    [theme.breakpoints.up('md')]: {
      gridColumn: '2 / span 7',
      marginBottom: theme.spacingPx(8),
      '.dialog-grid &': {
        gridColumn: '2 / span 6'
      }
    }
  },
  buttonContainer: {
    marginTop: theme.spacingPx(3),
    display: 'flex',
    gridColumn: '1 / span 8',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 3'
    }
  },
  button: {
    width: '100%'
  }
})

export default TextSlice
